.testimonial-content{
	text-align: center;

	img{
    	// box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
	}
	padding: 25px;
    position: relative;

	i{
		font-size: 60px;
	}

	p{
		margin-top: 30px;
		margin-bottom: 25px;
	}

}