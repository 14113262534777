.footer{
	padding: 60px 0px;
	background: $black;
}
.footer-widget{

	a{
		color: rgba(255,255,255,.5);
		text-transform: capitalize;
		&:hover{
			color: $primary-color;
		}
	}
}
	
