.service-home{
	position: relative;
}

.service-item{
	i{
		font-size: 35px;
		float: left;
		width: 65px;
		height: 120px;
	}
	
}