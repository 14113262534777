@import url('https://fonts.googleapis.com/css?family=Lato:400,600,700|Work+Sans:400,700,800');


html{
  overflow-x: hidden;
}

body {
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.166667px;
  line-height:1.7em;
  color: #818385;

}

p{
  color:#818385;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-family: $heading-font;
  color: $black;
  font-weight: 600;
}

h1 ,.h1{
  font-size: 45px;
  text-transform: none;
}

h2,.h2 {
  font-size: 42px;
  line-height: 52px;
}

h3,.h3 {
  font-size: 24px;
  line-height: 34px;
}

h4,.h4 {
  font-size: 20px;
  line-height: 30px;
}

h5,.h5 {
  font-size: 16px;
  line-height: 26px;
}

h6,.h6 {
  font-size: 14px;
}


.text-sm{
  font-size: 14px;
}